<template>
  <div class="case-details">
    <div v-if="caseDetails">
      <h1>成功案例</h1>
      <div>
        <img class="case-picture" :src="caseDetails.imgPath" :alt="caseDetails.imgTitle" />
        <div>
          <div class="case-title">{{ caseDetails.imgTitle }}</div>
          <div class="case-content">
            {{ caseDetails.imgDetails }}
          </div>
          <div class="case-advantages">
            <div>
              <img src="@/assets/image/case-details-icon-01.png" alt="专业团队" />
              <div>专业团队</div>
            </div>
            <div>
              <img src="@/assets/image/case-details-icon-02.png" alt="成熟设备" />
              <div>成熟设备</div>
            </div>
            <div>
              <img src="@/assets/image/case-details-icon-03.png" alt="管理创新" />
              <div>管理创新</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="productsList.length">
      <h2>相关产品</h2>
      <div class="related-products">
        <swiper loop :autoplay="{ delay: 5500, disableOnInteraction: false }" :pagination="{ clickable: true }">
          <swiper-slide v-for="(item, index) in productsList" :key="`products-${index}`" @click="jumpPage('ProductDetails', item.id)">
            <div class="banner-item">
              <div>
                <div class="product-name">{{ item.imgTitle }}</div>
                <div class="product-info">
                  {{ item.imgIntroduce }}
                </div>
                <div class="learn-more">了解更多>></div>
              </div>
              <img :src="item.imgPath" :alt="item.imgIntroduce" />
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./index.ts"></script>

<style lang="scss" scoped>
.case-details {
  h1,
  h2 {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    margin: 0;
    padding-bottom: 60px;
  }
  > div {
    &:first-child {
      padding-top: 74px;
      padding-bottom: 60px;
      width: $effectiveArea;
      margin: 0 auto;
      > div {
        display: flex;
        align-items: center;
      }
      .case-picture {
        width: 612px;
        height: 336px;
        margin-right: 38px;
      }
      .case-title {
        padding-top: 20px;
        padding-bottom: 30px;
        font-size: 30px;
      }
      .case-content {
        font-size: 14px;
        text-indent: 2em;
        min-height: 120px;
        margin-bottom: 22px;
      }
      .case-advantages {
        display: flex;
        font-size: 18px;
        text-align: center;
        > div {
          & + div {
            margin-left: 55px;
          }
        }
        img {
          height: 55px;
          padding-bottom: 18px;
        }
      }
    }
    & + div {
      padding-top: 60px;
      background-color: #e9ecf1;
      padding-bottom: 50px;
      .related-products {
        .banner-item {
          display: flex;
          width: $effectiveArea;
          margin: 0 auto;
          cursor: pointer;
          > div {
            &:first-child {
              width: 699px;
            }
          }
        }
        img {
          width: 612px;
          height: 336px;
          margin-left: 85px;
          padding-bottom: 55px;
        }
        .product-name {
          padding: 30px 0;
          font-size: 30px;
        }
        .product-info {
          font-size: 14px;
          min-height: 120px;
          max-height: 120px;
          margin-bottom: 20px;
        }
        .learn-more {
          background: #008fe0;
          border-radius: 4px;
          text-align: center;
          height: 45px;
          width: 144px;
          line-height: 45px;
          color: #fff;
        }
      }
    }
  }
}
</style>
