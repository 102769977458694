import { defineComponent, reactive, toRefs } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import { useRoute, useRouter } from "vue-router";
import { getByUrl } from "@/utils/http";

SwiperCore.use([Pagination, Autoplay]);

interface Data {
  caseDetails: {
    id: number;
    imgDetails: string;
    imgName: string;
    imgPath: string;
    imgTitle: string;
    solutionDetailsId: number;
  } | null;
  productsList: Array<{
    applicationScenarios: null;
    caseDetailsId: null;
    fieldParameter: null;
    firstTypeId: null;
    id: number;
    imgDetails: null;
    imgIntroduce: string;
    imgName: null;
    imgPath: string;
    imgTitle: string;
    secondTypeId: null;
    succId: null;
  }>;
  jumpPage: (name: string, productsId: number) => void;
}

export default defineComponent({
  name: "CaseDetails",
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { id } = route.params;

    const data: Data = reactive({
      caseDetails: null,
      productsList: [],
      jumpPage: (name, productsId) => {
        router.push({ name, params: { productsId } });
      }
    });
    getByUrl("/successCase/getDetails", { id }).then(res => {
      const { successCaseDetails, productsList } = res;
      data.caseDetails = successCaseDetails[0];
      data.productsList = productsList;
    });

    return {
      ...toRefs(data)
    };
  }
});
